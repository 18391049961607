body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  background-color: black;
}

h1 {
  font-family: "HVN";
  font-size: 31px;
  text-transform: uppercase;
}

#mc_embed_signup {
  background: #272626;
  clear: left;
  font-family: "Montserrat";
  font-size: 18px;
}

#mc_embed_signup_scroll {
  display: flex;
}

#mce-EMAIL {
  background-color: #272626;
  color: white;
  border: none;
  padding: 10px;
  height: 24px;
  flex: 1 1;
}

#mce-EMAIL::-webkit-input-placeholder {
  color: gray;
}

#mce-EMAIL:-ms-input-placeholder {
  color: gray;
}

#mce-EMAIL::placeholder {
  color: gray;
}

#mce-EMAIL:focus-visible {
  outline: none;
}

#mc-embedded-subscribe {
  background-color: #4b4a4a;
  transition: background-color .2s;
  border: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

#mc-embedded-subscribe:hover {
  background-color: #8c634a;
}

.optionalParent {
  flex: 0 0 56px;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(../../static/media/Montserrat.a3fcdff0.ttf) format('truetype');
}

@font-face {
  font-family: 'HVN';
  src: local('HVN'), url(../../static/media/HVN.50ebaffd.ttf) format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
